<template>
  <router-view v-if="apiState === 3"></router-view>
  <loader v-else class="mx-auto"></loader>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import enums from '@main/enums';
import Loader from '../Loader.vue';

export default {
  components: { Loader },
  computed: {
    ...mapState('producing', ['apiState']),
  },
  methods: {
    ...mapActions('producing', ['loadInfoProductsOptions', 'loadProducerProfileOptions']),
    ...mapMutations('producing', ['setApiState']),
  },
  async mounted() {
    this.setApiState(enums.LOADING);
    try {
      await this.loadInfoProductsOptions();
      await this.loadProducerProfileOptions();
    } catch (e) {
      this.setApiState(enums.ERROR);
    }
    this.setApiState(enums.LOADED);
  },
};
</script>
